import React from "react";
import styled, { keyframes, css } from "styled-components";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { isMobile } from "react-device-detect";

const logo = require("../images/icon-512.png");

const homeImage = require("../images/home.png");
const availabilitiesImage = require("../images/availabilities.png");
const scheduleImage = require("../images/schedule.png");
const switchImage = require("../images/switch.png");
const loopedImage = require("../images/looped.png");
const knowImage = require("../images/know.png");
const remyImage = require("../images/remy.png");

const S = {};

S.App = styled.div`
  padding: ${isMobile ? 20 : 40}px;
`;

const FloatIn = keyframes`
  from {
    opacity: 0;
    margin-top: 64px;
    margin-left: -16px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

S.Heading = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: ${isMobile ? 48 : 64}px;
  margin-bottom: 16px;

  color: #1c213c;
  animation: ${FloatIn} 1.2s;
`;

S.Description = styled.div`
  opacity: 0;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: ${isMobile ? 20 : 32}px;

  width: ${isMobile ? 100 : 70}%;
  margin-bottom: 32px;

  color: #1c213c;
  animation: ${FloatIn} 1.2s forwards;
`;

S.LinkButton = styled(Link)`
  background: #1c213c;
  padding: 8px 16px;
  font-family: Ubuntu;
  font-size: ${isMobile ? 16 : 24}px;
  color: white;
  text-decoration: none;
  border-radius: 6px;
`;

S.Link = styled.a`
  background: #1c213c;
  padding: 8px 16px;
  font-family: Ubuntu;
  font-size: ${isMobile ? 16 : 24}px;
  color: white;
  text-decoration: none;
  border-radius: 6px;
`;

export default () => (
  <S.App>
    <Router>
      <Header />

      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/demo/availabilities"
          component={DemoAvailabilities}
        />
        <Route exact path="/demo/schedule" component={DemoSchedule} />
        <Route exact path="/demo/switch" component={DemoSwitch} />
        <Route exact path="/demo/loop" component={DemoLoop} />
        <Route exact path="/demo/know" component={DemoKnow} />
        <Route exact path="/demo/remy" component={DemoRemy} />
        <Route exact path="/contact" component={Contact} />
      </AnimatedSwitch>
    </Router>
  </S.App>
);

S.Header = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
`;
S.Logo = styled.img`
  width: 60px;
  height: 60px;
`;
S.Name = styled.div`
  font-family: Montserrat;
  color: #1c213c;
  font-weight: 600;
  font-size: 36px;
  margin-left: 16px;
`;

const Header = () => (
  <S.Header>
    <a href="/" style={{ textDecoration: "none" }}>
      <S.Logo src={logo} />
    </a>
    <div style={{ flexGrow: 1 }} />
    <S.Link href="https://app.meetrecall.app" style={{marginRight: 20}}>App</S.Link>
    <S.Link href="mailto:hello@meetrecall.app">Get In Touch</S.Link>
  </S.Header>
);

S.Page = styled.div``;

const ImageFloatIn = (bottom, right) => keyframes`
  from {
    opacity: 0;
    right: -64px;
    bottom: -64px;
  }
  to {
    opacity: 1;
    right: ${right || 0};
    bottom: ${bottom || 0};
  }
`;

S.PageImage = styled.img`
  position: fixed;
  right: -64px;
  bottom: -64px;

  ${
    isMobile
      ? css`
          width: 90%;
          height: none;
        `
      : css`
          height: 80%;
        `
  }
  animation: ${props => ImageFloatIn(props.bottom, props.right)} 1s forwards;
  z-index: -1;
`;

const Home = ({}) => (
  <S.Page>
    <S.Heading>Meet Recall,</S.Heading>
    <S.Description>
      An integrated scheduling and logistics platform for healthcare providers.
    </S.Description>

    <S.LinkButton to="/demo/availabilities">How it works</S.LinkButton>

    <S.PageImage src={homeImage} />
  </S.Page>
);

const DemoAvailabilities = ({}) => (
  <S.Page>
    <S.Heading>1. Collect Availabilities</S.Heading>
    <S.Description>
      We automate finding out when everyone’s available and get answers in
      seconds.
    </S.Description>
    <S.LinkButton to="/">Home</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/demo/schedule">Next</S.LinkButton>
    <S.PageImage src={availabilitiesImage} />
  </S.Page>
);
const DemoSchedule = ({}) => (
  <S.Page>
    <S.Heading>2. Schedule in a click.</S.Heading>
    <S.Description>
      Our MagicAssign™️ technology works to understand your rules and automate
      your scheduling. Hours just became seconds.
    </S.Description>

    <S.LinkButton to="/demo/availabilities">Previous</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/demo/switch">Next</S.LinkButton>
    <S.PageImage src={scheduleImage} />
  </S.Page>
);

const DemoSwitch = ({}) => (
  <S.Page>
    <S.Heading>3. Switch in an instant.</S.Heading>
    <S.Description>
      Trade, switch, cover, post, whatever it is we’ve got it down. Plus,
      MagicAssign™️ can offer trades intelligently, all while abiding by your
      team’s specific policies.
    </S.Description>
    <S.LinkButton to="/demo/schedule">Previous</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/demo/loop">Next</S.LinkButton>
    <S.PageImage src={switchImage} />
  </S.Page>
);

const DemoLoop = ({}) => (
  <S.Page>
    <S.Heading>4. Stay Looped In</S.Heading>
    <S.Description>
      Text, email, dashboards, we’ve got you covered. We reach your team however
      they want.
    </S.Description>

    <S.LinkButton to="/demo/switch">Previous</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/demo/know">Next</S.LinkButton>
    <S.PageImage src={loopedImage} />
  </S.Page>
);

const DemoKnow = ({}) => (
  <S.Page>
    <S.Heading>5. Know What’s Up.</S.Heading>
    <S.Description>
      Where are we understaffed? When do our switches happen most often? Are
      hours being split equitably? Whatever your question, Recall has answers.
    </S.Description>

    <S.LinkButton to="/demo/loop">Previous</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/demo/remy">Next</S.LinkButton>
    <S.PageImage src={knowImage} bottom="-10%" />
  </S.Page>
);

const DemoRemy = ({}) => (
  <S.Page>
    <S.Heading>6. Just say hello.</S.Heading>
    <S.Description>
      You're busy. Recall includes Remy, your new AI powered assistant that's
      only a text message away. Anything Recall can, Remy can do.
    </S.Description>

    <S.LinkButton to="/demo/know">Previous</S.LinkButton>
    <br />
    <br />
    <br />
    <S.LinkButton to="/">Home</S.LinkButton>
    <S.PageImage src={remyImage} bottom={"-8%"} right={"-5%"} />
  </S.Page>
);

S.ContactEmailLabel = styled.div`
  color: #1c213c;
  font-family: Ubuntu;
  font-size: 24px;
  margin-bottom: 8px;
`;

S.ContactEmail = styled.input`
  background: #eee;
  border: none;
  font-family: Ubuntu;
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

S.ContactMessage = styled.textarea`
  background: #eee;
  border: none;
  font-family: Ubuntu;
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  width: 100%;

  height: 0;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
`;
const Contact = ({}) => (
  <S.Page>
    <div style={{ display: "flex", flexDirection: "column" }}>
      <S.Heading>Let's chat,</S.Heading>
      <br />
      <br />
      <S.ContactEmailLabel>Email</S.ContactEmailLabel>
      <S.ContactEmail />
      <br />
      <S.ContactEmailLabel>Message</S.ContactEmailLabel>
      <S.ContactEmail />
    </div>
  </S.Page>
);
